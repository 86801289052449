<style scoped>
  /* .field-error {
  color: red;
} */
  
.invalid input {
        border: 1px #EB0600 solid
}
.invalid {
        color: #EB0600
}
</style>
<script>
import 'vue-select/dist/vue-select.css';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import axios from 'axios';
// import Swal from "sweetalert2";
import Vue from 'vue';
import DataJenisBarang from "./jenis_barang/index"
import Satuan from "./satuan/index"


import VueLoading from 'vuejs-loading-plugin'

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

// using default options
Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})



/**
 * Advanced table component
 */
export default {
  page: {
    title: "Data Penanaman",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DataJenisBarang, Satuan },
  data() {
    return {
      // tableData: tableData,
      title: "Master Barang",
      items: [
        {
          text: "Pantau Kebun",
          href: "/"
        },
        {
          text: "Master Barang",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "nama",
      sortDesc: false,
      fields: [
        { key: "nama_satuan", label: 'Nama Satuan', sortable: true },
        { key: "jenis_satuan", label: 'Jenis Satuan', sortable: true },
        'Aksi',
      ],
      baseapi: localStorage.getItem("baseapi"),
      user: JSON.parse(localStorage.getItem('user')),
      satuan:[],
      datasatuan:[],
      datalokasi:[],
      optionscaratanam: [
          { value: 'Tegel', text: 'Tegel' },
          { value: 'Legowo', text: 'Legowo' },
      ],
      optionsbiji: [
          { value: 1, text: 1 },
          { value: 2, text: 2 },
      ]
    };
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
            <div class="card-body">
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">
                <b-tab active>
                <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Satuan</span>
                </template>
                <Satuan />
                <!-- <p class="mb-0">{{ text }}</p> -->
                </b-tab>
                <b-tab>
                <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Jenis Barang</span>
                </template>
                <DataJenisBarang />
                <!-- <p class="mb-0">{{ content }}</p> -->
                </b-tab>
            </b-tabs>
            </div>
            <!-- end card-body -->
        </div>
      </div>
    </div>
  </Layout>
</template>