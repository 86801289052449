<style scoped>
  /* .field-error {
  color: red;
} */
  
.invalid input {
        border: 1px #EB0600 solid
}
.invalid {
        color: #EB0600
}
</style>
<script>
import 'vue-select/dist/vue-select.css';
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import Vue from 'vue';

import VueLoading from 'vuejs-loading-plugin'

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

// using default options
Vue.use(VueLoading)

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})



/**
 * Advanced table component
 */
export default {
  page: {
    title: "Data Penanaman",
    meta: [{ name: "description", content: appConfig.description }]
  },
//   components: { PageHeader },
  data() {
    return {
      // tableData: tableData,
      title: "Data Penanaman",
      items: [
        {
          text: "Pantau Kebun",
          href: "/"
        },
        {
          text: "Data Penanaman",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "nama",
      sortDesc: false,
      fields: [
        { key: "nama", label: 'Nama', sortable: true },
        'Aksi',
      ],
      baseapi: localStorage.getItem("baseapi"),
      user: JSON.parse(localStorage.getItem('user')),
      jenisBarang:[],
      dataJenisBarang:[],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.jenisBarang.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.loaddata()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },


    // loadData Kelompok Tani
    loaddata(){
      this.$loading(true);
      axios.get(this.baseapi+"api/jenis_barang", {
          headers: {
              Authorization: `Bearer `+this.user.accessToken
          }
      }).then(response => {
          this.$loading(false);
          this.jenisBarang = response.data;
      }).catch(error=>{
          this.$loading(false);
          if (error.response.data.message === 'Unauthorized!') {
            Swal.fire({
              title: "Akses Anda Terputus",
              text: "Silahkan Login Kembali",
              type: "error",
            }).then((r) => {
              console.log(r)
              localStorage.removeItem('user');
              this.$router.push({ path: '/login' })
            });
          }
          console.log(error)
          return error
          });
    },

    // tambah data
    handleOkTambah(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.tambahdata()
    },

    tambahdata(){
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
          const nama = this.dataJenisBarang.nama;

        axios
            .post(
              this.baseapi+"api/jenis_barang",
            {
                nama,
            },
            {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
                }
            }
            )
            .then((res) => {
                Swal.fire({
                  icon: 'success',
                  title: "Data Berhasil Ditambah",
                  showConfirmButton: false,
                  timer: 1500
                })
                this.dataJenisBarang = []
                this.loaddata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            return err;
        });

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        });
      });
    },
    // end tambah data

    // edit data
    editdata(id) {
       this.$refs.formedit.validate().then(success => {
        if (!success) {
          return;
        }
          axios
              .put(
              this.baseapi+`api/jenis_barang/${id}`,
              {
                  nama : this.dataJenisBarang.nama,
              }
              ,{
                  headers:{
                    Authorization: `Bearer `+this.user.accessToken
                  }
              })
              .then((res) => {
                  Swal.fire({
                    icon: 'success',
                    title: "Data Berhasil Diubah",
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.dataJenisBarang = []
                  this.loaddata()
              return res;
              })
              .catch((err) => {
              console.log(err)
              Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
              })
              return err;
          });
          // Push the name to submitted names
          // this.submittedNames.push(this.name)
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-edit')
          })
       })
    },

    // mendapatkan data satuan
    getperdata(id){
        axios.get(this.baseapi+`api/jenis_barang/${id}`, {
                headers: {
                    Authorization: `Bearer `+this.user.accessToken
                }
            }).then(response => {
            this.dataJenisBarang = response.data;
        });
    },

    // hapusdata
    hapusdata(id){
        Swal.fire({
        title: 'Hapus Data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Batal',
        confirmButtonText: 'Ya'
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            axios
              .delete(
              this.baseapi+`api/jenis_barang/`+id,
              {
                  headers: {
                      Authorization: `Bearer `+this.user.accessToken
                  }
              }
              )
              .then((res) => {
                  Swal.fire({
                    icon: 'success',
                    title: "Data Berhasil Dihapus",
                    showConfirmButton: false,
                    timer: 1500
                })
                this.loaddata()
              return res;
              })
              .catch((err) => {
              console.log(err)
              Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
              })
              return err;
          });
        }
        })
    },
  }
};
</script>

<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="form-group row">
              <div class="col-sm-6">
                <b-button variant="success" v-b-modal.modal-tambah
                  ><i class="fas fa-plus"></i> Tambah</b-button
                >
              </div>
              <div class="col-sm-6 text-end">
              </div>
            </div>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="jenisBarang"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(aksi)="data">
                <b-button variant="info" style="margin-right:5px" v-b-modal.modal-edit @click="getperdata(data.item.id)"><i class="fas fa-edit"></i></b-button>
                <b-button variant="danger" @click="hapusdata(data.item.id)"><i class="far fa-trash-alt"></i></b-button>
              </template>
              </b-table>
            </div>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                
              </div>
              <!-- End search -->
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal tambah -->
      <b-modal
        id="modal-tambah"
        title="Tambah Data"
        cancel-title="Close"
        ok-title="Accept"
        cancel-variant="outline-secondary"
        size="md"
      >
        <ValidationObserver ref="form">
        <form
          ref="form"
          @submit.stop.prevent="tambahdata"
        >
        <b-row>
          <b-col md="12">
            <b-form-group
                label="Nama"
                label-for="name"
                class="mb-3"
            >
            <ValidationProvider name="Nama" rules="required" v-slot="{ classes, errors }">
                <b-form-input
                id="name"
                v-model="dataJenisBarang.nama"
                />
                <span :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        </form>
      </ValidationObserver>
      
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              
              style="float:right"
              @click="handleOkTambah"
            >
            <i class="fas fa-save"></i>
            
              Simpan
            </b-button>
          </div>
        </template>
      </b-modal>

    <!-- modal edit -->
      <b-modal
        id="modal-edit"
        title="Edit Data"
        cancel-title="Close"
        ok-title="Accept"
        cancel-variant="outline-secondary"
        size="md"
      >
        <ValidationObserver ref="formedit">
        <form
          ref="form"
          @submit.stop.prevent="editdata"
        >

        <b-row>
            <b-col md="12">
            <b-form-group
                label="Nama"
                label-for="name"
                class="mb-3"
            >
            <ValidationProvider name="Nama" rules="required" v-slot="{ classes, errors }">
                <b-form-input
                id="name"
                v-model="dataJenisBarang.nama"
                />
                <span :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
         
        </form>
      </ValidationObserver>
      

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              
              style="float:right"
              @click="editdata(dataJenisBarang.id)"
            >
            <i class="fas fa-save"></i>
            
              Simpan
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
</template>